const CodeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="24.563"
      viewBox="0 0 30 24.563"
    >
      <g id="Gruppe_11" data-name="Gruppe 11" transform="translate(-1 -3.718)">
        <path
          id="Pfad_12"
          data-name="Pfad 12"
          d="M8,11,2,16l6,5"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Pfad_13"
          data-name="Pfad 13"
          d="M24,11l6,5-6,5"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Pfad_14"
          data-name="Pfad 14"
          d="M20,5,12,27"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default CodeIcon;
