const GridIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="22"
      viewBox="0 0 26 22"
    >
      <g id="Gruppe_25" data-name="Gruppe 25" transform="translate(-3 -5)">
        <path
          id="Pfad_64"
          data-name="Pfad 64"
          d="M13,13V26"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Pfad_65"
          data-name="Pfad 65"
          d="M4,13H28"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Pfad_66"
          data-name="Pfad 66"
          d="M27,6H5A1,1,0,0,0,4,7V25a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1V7A1,1,0,0,0,27,6Z"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default GridIcon;
